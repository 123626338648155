/** @jsx jsx */
import {
  contactUsTextStyles,
  contactUsTitleStyles,
  contactUsContainerStyles,
  contactUsDetailsContainerStyles,
  contactUsSubtitleContainerStyles,
} from "./styles"
import { useIntl } from "react-intl"
import { Styled, jsx, Box } from "theme-ui"
import {
  translate,
  translateCustom,
} from "gatsby-theme-facet-default/src/helpers/grammar"

/**
 * Type definition for props required for "ContactUs" component.
 *
 * @interface
 */
interface ContactUsProps {
  title: string
  subTitle: string
  howToReach: {
    [key: string]: {
      text: string
      tel: string
    }
  }
}

/**
 * Component redering the fields/data for "Contact" page.
 *
 * Used only on "contact" page.
 *
 * @param {ContactUsProps} param
 *
 * @returns {JSX.Element}
 */
const ContactUs = ({
  title,
  subTitle,
  howToReach,
}: ContactUsProps): JSX.Element => {
  const intl = useIntl()

  // Translate extra data
  const emailText = translate(intl, "contactUs.howToReach.email.text")
  const email = translate(intl, "contactUs.howToReach.email.email")
  const phone = translate(intl, "contactUs.howToReach.phone.tel")
  const websiteText = translate(intl, "contactUs.howToReach.website.text")
  const websiteLink = translate(intl, "contactUs.howToReach.website.link")
  const description = translateCustom(intl, "contactUs.description", {
    phone: (
      <a key={0} href={`tel:${phone}`}>
        {phone}
      </a>
    ),
    website: (
      <a key={1} href={websiteLink} target={"_blank"}>
        {websiteText}
      </a>
    ),
  })

  return (
    <Box sx={contactUsContainerStyles}>
      <Styled.h2 sx={contactUsTitleStyles}>{title}</Styled.h2>
      <Styled.p sx={contactUsTextStyles}>{description}</Styled.p>
      <Box sx={contactUsSubtitleContainerStyles}>
        <Styled.p sx={contactUsTextStyles}>{subTitle}</Styled.p>
      </Box>
      <Box sx={contactUsDetailsContainerStyles}>
        <Styled.p>
          Fax: <a href={`tel:${howToReach.fax.tel}`}>{howToReach.fax.tel}</a>
        </Styled.p>
        <Styled.p>
          {emailText}: <a href={`mailto:${email}`}>{email}</a>
        </Styled.p>
      </Box>
    </Box>
  )
}

export default ContactUs
