import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "gatsby-theme-facet-default/src/helpers/theme"

export const contactUsContainerStyles: SxStyleProp = {
  pb: 7,
  px: 7,
  minHeight: "90vh",
  flexDirection: "column",
  pt: createResponsiveStyles(9, 12),
  textAlign: "center",
  maxWidth: 850,
  m: "0 auto",
}

export const contactUsTitleStyles: SxStyleProp = {
  fontWeight: "bold",
  pb: createResponsiveStyles(7, "70px"),
  color: "primary",
}

export const contactUsTextStyles: SxStyleProp = {
  color: "text",
  fontFamily: "heading",
  fontSize: createResponsiveStyles(1, 3),
  " > a ": {
    color: "text",
    fontWeight: "bold",
  },
}

export const contactUsDetailsContainerStyles: SxStyleProp = {
  p: {
    color: "primary",
    fontFamily: "heading",
    fontWeight: "bold",
    fontSize: createResponsiveStyles(1, 3),
    "+ p": {
      pt: createResponsiveStyles(5, 3),
    },
  },
  a: {
    color: "primary",
  },
}

export const contactUsSubtitleContainerStyles: SxStyleProp = {
  pb: createResponsiveStyles(7, 6),
  pt: createResponsiveStyles(6, 8),
}
